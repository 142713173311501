import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: Object,
    options: Object,
  },
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              color: 'rgba(242, 242, 243, 1)',
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              color: 'rgba(242, 242, 243, 1)',
            },
          },
        ],
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 20,
          lineWidth: 0,
        },
      },
      ...this.options,
    })
  },
}
